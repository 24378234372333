<template>
  <VacationApplyInBatchDialog show :selectedUsers="[]" />
</template>
<script>
import VacationApplyInBatchDialog from "@/components/sign/vacation/VacationApplyInBatchDialog.vue";

export default {
  name: "ApplyVacationMessage",
  components: { VacationApplyInBatchDialog },
};
</script>
